<template>
  <div class="time-page page">
    <div class="container">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: "Home",
  created() {
    if(window.Telegram.WebApp.platform == 'unknown' && window.location.hostname !== 'localhost') {
      window.location.href = "https://t.me/happy_meter_bot";
    }
  }
};
</script>


<style scoped lang="scss">
.title {
  color: #897a6c;
  text-align: center;
}
.time-page {
  padding: 28px 20px 28px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.time-form {
  height: 136px;
  margin: 16px 0;
}
</style>
